@use "../utils/functions" as *;

.default {
  overflow-y: hidden;

  &_header {
    text-align: center;
    z-index: 1;

    &_h2 {
      color: #fbed63;
      text-shadow: 0px 1px 0px #6e0637;
      font-family: var(--ff-russoOne);
      font-size: 1.23138rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }

    &_h1 {
      color: #ffffff;
      text-shadow: 1px 3px 0px #6e0637;
      font-family: var(--ff-russoOne);
      font-size: 2.06888rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
  }

  &_actionButton {
    z-index: 1;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;

    &_register {
      height: 4.5rem;
      width: max-content;

      img {
        height: 4.5rem;
        width: auto;
        object-fit: contain;
      }
    }

    &_login {
      color: #ffffff !important;
      font-family: var(--ff-montserrat);
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.15rem;
      border-bottom: 1px solid #ffffff;
    }
  }
}
