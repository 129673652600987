@use "../utils/functions" as *;

.earn {
  overflow-y: unset !important;
}

.earn_body {
  .profile_body_prizeItems {
    height: 50%;

    img {
      height: calc(100% - 2.75rem);
    }
  }

  .earnpage-marqueeContainer {
    border: 1px solid #000000;
    background: #ec6fdd;
    height: 2.75rem;
    font-size: 0.875rem;
    color: #fbeb5c;
    overflow-x: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: center;

    &::-webkit-scrollbar {
      display: none;
    }

    div {
      position: absolute;
      height: 2.75rem;
      top: 0;
      left: 0;
      transform: translate(-50%);
      width: max-content;
      z-index: 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: marquee-animation 20s linear infinite alternate;

      span {
        margin-left: 1.25rem;
        color: #fbeb5c;
        text-align: center;
        font-family: var(--ff-montserrat);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}

.border-rect {
  margin: 0 auto;
  width: 100%;
  max-width: toRem(465);
  height: 0.5625rem;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  background: #3b65ff;

  &.noTranslate {
    transform: translateY(-3.2rem);
  }
}

.earnpage-rewardsList {
  padding: 1.3rem;
  border-bottom: 1px solid #000000;
  background: #395bd6;
  box-shadow: 0px 3px 0px 0px #3b65ff inset;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  display: -ms-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto !important;
  max-width: toRem(465);
  margin-bottom: 3.1rem !important;

  &::-webkit-scrollbar {
    display: none;
  }

  .earnpage-rewardsListItem {
    margin-left: 0.85rem;

    .rewardsListItem-rewardImage {
      width: 11.75rem;
      height: 14.0625rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      justify-content: center;

      &:last-of-type {
        background: linear-gradient(180deg, #ef7170 0%, #ea6f6e 100%);
        border-radius: 0.5rem;
        border: 1px solid #000;
      }

      img {
        width: 10.625rem;
        height: 10.875rem;
        object-fit: contain;
      }
    }

    .rewardsListItem-rewardValue {
      height: 3.125rem;
      width: 100%;
      padding: 0.25rem 0.5rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #ffeb34;
      color: #000000;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
      border-top: 1px solid #000;
      box-shadow: 0px 5px 0px 0px #fff59b inset, 0px -4px 0px 0px #b5a50f inset;
      font-family: var(--ff-montserrat);
      text-align: center;

      .rewardValueTop {
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 600;
      }

      .rewardValueBottom {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
      }
    }

    background: linear-gradient(180deg, #191945 0%, #2e2e8d 100%);
    border-radius: 0.5rem;
    border: 1px solid #000;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      background: linear-gradient(180deg, #ef7170 0%, #ea6f6e 100%);
    }
  }

  &.defaultPage {
    padding: 0;
    background: transparent;
    border: 0;
    box-shadow: none;
    justify-content: flex-start;
    align-self: center;
    transform: translateY(0);
    overflow-y: hidden;
    margin-bottom: 0 !important;

    .rewardsListItem-rewardImage {
      background: none !important;

      &:first-of-type {
        background: none !important;
      }

      &:last-of-type {
        background: none !important;
      }
    }
  }
}

.slotMachine-slotLine {
  width: 100%;
  height: 12.0625rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  margin-top: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .slotMachine-slotBg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    display: -ms-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: space-between;

    .slotMachine-luckySlotPointers {
      display: -ms-flexbox;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      justify-content: center;

      &:last-of-type {
        transform: rotateY(180deg);
      }

      img {
        width: 1.5625rem;
        height: toRem(19);
      }
    }
  }

  div:not([class]) {
    height: 6.9rem;
    width: calc(100% - (1.5rem * 2));
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    margin-top: 1.6rem;

    .slotMachine-slotItems {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      flex-direction: column;
      align-items: center;
      width: 33%;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);

      &:nth-of-type(1) {
        left: 0;
      }

      &:nth-of-type(2) {
        left: 50%;
        transform: translateX(-50%) translateY(50%);
      }

      &:nth-of-type(3) {
        right: 0;
      }

      &.runningSlots.faster {
        animation: slot-animation 250ms linear infinite alternate;

        &:nth-of-type(2) {
          animation: slot-animation 250ms linear infinite alternate-reverse;
        }
      }

      &.runningSlots.slower {
        animation: slot-animation 400ms linear infinite alternate;

        &:nth-of-type(2) {
          animation: slot-animation 400ms linear infinite alternate-reverse;
        }
      }

      .slotMachine-itemImages {
        display: -ms-flexbox;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        justify-content: center;
        height: 2.75rem;

        img {
          width: toRem(28);
          height: toRem(28);
          object-fit: contain;
        }
      }
    }
  }
}

.earnpage-spinCount {
  height: 3rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: center;
  justify-content: center;
  margin-top: 4px;
  color: #fff;
  text-align: center;
  font-family: var(--ff-montserrat);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

@keyframes marquee-animation {
  from {
    left: -300%;
  }
  to {
    left: 0;
  }
}

@keyframes slot-animation {
  from {
    bottom: 150%;
  }
  to {
    bottom: -150%;
  }
}
