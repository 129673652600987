@use "../utils/functions" as *;

.register {
  overflow-y: hidden;

  &_header {
    text-align: center;
    z-index: 1;
    color: #ffffff;
    text-shadow: 1px 3px 0px #6e0637;
    font-family: var(--ff-russoOne);
    font-size: 2.06888rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    position: relative;

    &_stars {
      position: absolute;
      width: 1.6875rem;
      height: 2.125rem;
      z-index: 2;
      top: 0;
      right: 0;
      transform: translateX(1.135rem) translateY(-0.65rem);
    }
  }

  &_body {
    z-index: 2;
    width: 19.5rem;
    isolation: isolate;
    height: 100%;

    &_banner {
      position: relative;
      height: 12.1875rem;

      &_bannerImg {
        position: absolute;
        z-index: -1;
      }

      &_bannerText {
        text-align: center;
        height: calc(12.1875rem - 5.6875rem);
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        justify-content: center;

        &_h2 {
          width: 16.3125rem;
          color: #fff;
          text-align: center;
          text-shadow: 0px 1px 0px #6e0637;
          font-family: var(--ff-russoOne);
          font-size: 1rem;
          font-weight: 400;
          position: relative;

          &_stars {
            position: absolute;
            right: 0.25rem;
            top: 0;
            transform: translateY(-50%);
          }
        }
      }
    }

    &_form {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      flex-direction: column;
      margin-top: 0.5rem;

      &_controlGroup {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        height: 3.125rem;
        border-radius: 0.25rem;
        border: 1px solid #000000;
        background: #ffffff;
        margin-top: 0.35rem;

        &_icon {
          width: 2.18256rem;
          height: 100%;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;

          align-items: center;
          justify-content: center;
        }

        &_control {
          width: 100%;
          height: 100%;
          color: #000000;
          font-family: var(--ff-montserrat);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;

          &::placeholder {
            color: #c5c5c5;
          }
        }
      }
    }
    @media screen and (max-height: 660px){
      overflow-x: auto;
    }
  }

  &_actionButton {
    z-index: 1;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 4px;
    height: toRem(91);

    &_registerButton {
      height: auto;
      width: toRem(244);

      img {
        height: auto;
        width: toRem(244);
        object-fit: cover;
      }
    }

    &_loginButton {
      color: #ffffff !important;
      font-family: var(--ff-montserrat);
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.15rem;
      border-bottom: 1px solid #ffffff;
    }
  }
}

