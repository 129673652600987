@use "../utils/functions" as *;

.coinBalance {
  z-index: 2;
  width: 22.875rem;
  margin: 0 auto;
  height: 3.45rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: center;
  justify-content: space-between;

  &_coinsContainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    padding: 0 0.8rem;

    &_balance {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      position: relative;

      img {
        position: absolute;
        left: 0;
        transform: translateX(-0.3rem);
      }

      &_amount {
        height: 1.6875rem;
        border-radius: 312.5rem;
        background: #380993;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        padding: 0 1rem 0 2rem;
        color: #fff;
        font-family: var(--ff-montserrat);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
      }

      &:first-of-type {
        height: toRem(31);

        img {
          width: toRem(31);
          height: toRem(31);
        }

        .coinBalance_coinsContainer_balance_amount {
          padding: 0 1rem 0 2rem;
        }
      }

      &:last-of-type {
        height: toRem(28);
        margin-left: 0.75rem;

        img {
          width: toRem(32);
          height: toRem(28);
        }

        .coinBalance_coinsContainer_balance_amount {
          padding: 0 1rem 0 2.2rem;
        }
      }
    }
  }

  &_logoutBtn {
    margin-right: 0.69rem;
    margin-left: 0.5rem;
    padding-top: 0.25em;
    width: 5.5rem;
    height: 1.875rem;
    border-radius: 1.6875rem;
    border: 2px solid #380993;
    background: #ff695f;
    box-shadow: 0px -4px 0px 0px #940f06 inset;
    color: #ffffff;
    font-family: var(--ff-montserrat);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: flex-start;
    justify-content: center;
  }
}

.bottomNavbar {
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: toRem(465);
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.35rem;
  background: #395bd6;
  height: 3.25rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  border-bottom: 0;

  &_navigationActions {
    width: 100%;
    padding: 0.2rem;
    height: 2.625rem;
    border-radius: 1.875rem;
    background: #1e3482;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: space-between;

    li {
      width: 100%;
    }

    &_actionBtns {
      min-width: 5rem;
      height: 2.375rem;
      border-radius: 1.6875rem;
      border: 2px solid #c0cdff;
      background: #3b65ff;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      flex-direction: row;
      align-items: center;

      &_icons {
        width: 1.25rem;
        height: 2.375rem;
        margin: 0 0.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          width: 1.25rem;
          height: 1.25rem;
          object-fit: contain;
        }
      }

      &_name {
        display: inline-flex;
        align-items: center;
        color: #c0cdff;
        font-family: var(--ff-russoOne);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
      }

      &.centerActionButton {
        margin: 0 0.25rem;
      }

      &.active {
        border: 2px solid #452534;
        background: #ea6f6e;

        .bottomNavbar_navigationActions_actionBtns_name {
          color: #ffffff;
        }
      }
    }
  }
}
