@use "../utils/functions" as *;

.generatePass {
  overflow-y: hidden;

  &_header {
    text-align: center;
    z-index: 1;
    color: #ffffff;
    text-shadow: 1px 3px 0px #6e0637;
    font-family: Russo One;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    position: relative;

    &_stars {
      position: absolute;
      width: 1.6875rem;
      height: 2.125rem;
      z-index: 2;
      top: 0;
      right: 0;
      transform: translateX(1.135rem) translateY(-0.65rem);
    }
  }

  &_passContainer {
    width: 19.5rem;
    z-index: 2;
    height: calc(100% - 0.7rem);
    position: relative;
    isolation: isolate;

    &_passBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      justify-content: center;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    &_passTitle {
      height: 18.75%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      text-shadow: 0px 2.857142925262451px 0px #6e0637;
      font-family: var(--ff-russoOne);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
    }

    &_passBody {
      margin: 0 1.44rem 0 1.94rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      flex-direction: column;
      justify-content: center;
      font-family: var(--ff-montserrat);

      &.firstOfType {
        height: 15.25%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div:last-of-type {
          min-width: 5rem;
        }

        div:first-of-type {
          max-width: 10rem;
        }
      }

      &.secondOfType {
        height: 13.8%;
      }

      &.thirdOfType {
        height: 13.3%;
      }

      &_label {
        color: #8b7f1b;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
      }

      &_value {
        color: #000;
        font-size: 0.95rem;
        font-style: normal;
        font-weight: 700;
        margin-top: 2px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &_sponsor {
        font-family: var(--ff-montserrat);
        margin: 0 1.44rem 0 1.94rem;
        margin-top: 0.5rem;
        height: 4.5rem;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;

        img {
          width: 5.49863rem;
          height: 0.91206rem;
          margin-left: 5px;
        }
      }
    }
  }

  &_actionButton {
    z-index: 1;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: flex-end;
    justify-content: center;
    margin-top: 0.4rem;
    height: 5.375rem;

    &_registerButton {
      height: auto;
      width: toRem(291);

      img {
        height: auto;
        width: toRem(291);
        object-fit: cover;
      }
    }
  }
}
