:root {
  --ff-russoOne: "Russo One", sans-serif;
  --ff-montserrat: "Montserrat", sans-serif;
}

html, html:focus-within {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}
*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: inherit;
  -webkit-tap-highlight-color: transparent;
}
@media (prefers-reduced-motion: reduce) {
  *, *::before, *::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 90rem;
  min-width: 24.375rem;
  margin-inline: auto;
  min-height: 100vh;
  min-height: 100dvh;
  font-size: 1rem;
  font-weight: 400;
  line-height: var(--lh-base);
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
  background: #e0fdf9;
  -ms-overflow-style: none;
  scrollbar-width: 0;
}
body::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3 {
  line-height: var(--lh-headings);
  margin-block: 0;
}

p {
  margin-block: 0;
  color: inherit;
}

a, a:visited, a:active {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

ul:not(.list),
li:not(.list) {
  list-style-type: none;
  margin: 0;
}

img,
svg,
video {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
}

input,
select {
  border: none;
  background: transparent;
  font: inherit;
  color: inherit;
}
input:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none;
}
input[type=radio], input[type=checkbox] {
  cursor: pointer;
}
input:disabled {
  cursor: not-allowed;
}
input:disabled::-moz-placeholder {
  color: transparent !important;
}
input:disabled:-ms-input-placeholder {
  color: transparent !important;
}
input:disabled::placeholder {
  color: transparent !important;
}

textarea {
  resize: none;
  border: none;
  font: inherit;
  background: transparent;
  color: inherit;
}
textarea:focus {
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: inherit;
  background-color: transparent;
  color: inherit;
}
button:disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 38.74875em) {
  html,
html:focus-within {
    font-size: 95%;
  }
}
@media screen and (max-width: 21.87375em) {
  html,
html:focus-within {
    font-size: 90%;
  }
}
@media screen and (max-width: 18.75em) {
  html,
html:focus-within {
    font-size: 85%;
  }
}
/* --- Header Bar Long --- */
.headerBar {
  height: 6.8125rem;
  margin-top: 0.75rem;
  font-family: var(--ff-russoOne);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.headerBar_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.8125rem;
  z-index: 0;
}
.headerBar_bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

/* --- Header Bar Short --- */
.headerShort {
  height: 3.75rem;
  margin-top: 0.75rem;
  font-family: var(--ff-russoOne);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.headerShort_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.75rem;
  z-index: 0;
}
.headerShort_bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

/* --- Machine Body --- */
.machineBody {
  padding: 1.7rem 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.machineBody_bgImg {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.machineBody_bgImg img {
  width: 23rem;
  -o-object-fit: fill;
     object-fit: fill;
}

/* --- Machine Button --- */
.machineButton {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 9.125rem;
  overflow: hidden;
}
.machineButton_bgImg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.machineButton_bgImg img {
  width: 29.0625rem;
  height: 100%;
  max-width: 29.0625rem;
  max-height: 11.75rem;
}

/* --- App Spinner --- */
.appSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: 100dvh;
}
.appSpinner .appSpinner-spinner {
  width: 4rem;
  height: 4rem;
  border: 5px dotted #000000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  -webkit-animation: rotation 2s linear infinite;
          animation: rotation 2s linear infinite;
}

/* --- Modal --- */
.modalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: rgba(3, 3, 83, 0.8);
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalOverlay .modalBody {
  width: 20rem;
  height: 20rem;
  border: 1px solid #000000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #5a7aef;
  box-shadow: 0px 3px 0px 0px #3b65ff inset;
}
.modalOverlay .modalBody.unluckyBody {
  background: #ee7170;
}
.modalOverlay .modalBody .modalHeader {
  height: 3.625rem;
  width: 20rem;
  margin-top: 2px;
  color: #ffffff;
  text-align: center;
  font-family: var(--ff-russoOne);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.modalOverlay .modalBody .modalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(16.375rem - 5px);
  padding: 1.12rem 1.2rem 1.12rem 1.2rem;
  border-top: 1px solid #000000;
}
.modalOverlay .modalBody .modalContent .modalTitle {
  color: #ffeb34;
  text-align: center;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  font-family: var(--ff-russoOne);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}
.modalOverlay .modalBody .modalContent .modalIllustration {
  width: 8rem;
  height: 8rem;
  margin: 0.62rem 0;
}
.modalOverlay .modalBody .modalContent .modalIllustration.unlockMIllustration {
  width: 17.6875rem;
  height: 13.125rem;
  margin-top: -2.5rem;
}
.modalOverlay .modalBody .modalContent .modalIllustration img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.modalOverlay .modalBody .modalContent .modalActionButton {
  width: 14rem;
  height: 2rem;
  border-radius: 1.6875rem;
  border: 2px solid #452534;
  background: #ea6f6e;
  color: #fff;
  text-align: center;
  font-family: var(--ff-russoOne);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.04em;
}
.modalOverlay .modalBody .modalContent .modalActionButton.unluckyAction {
  background: #5a7aef;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.coinBalance {
  z-index: 2;
  width: 22.875rem;
  margin: 0 auto;
  height: 3.45rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coinBalance_coinsContainer {
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
}
.coinBalance_coinsContainer_balance {
  display: flex;
  align-items: center;
  position: relative;
}
.coinBalance_coinsContainer_balance img {
  position: absolute;
  left: 0;
  transform: translateX(-0.3rem);
}
.coinBalance_coinsContainer_balance_amount {
  height: 1.6875rem;
  border-radius: 312.5rem;
  background: #380993;
  display: flex;
  align-items: center;
  padding: 0 1rem 0 2rem;
  color: #fff;
  font-family: var(--ff-montserrat);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}
.coinBalance_coinsContainer_balance:first-of-type {
  height: 1.9375rem;
}
.coinBalance_coinsContainer_balance:first-of-type img {
  width: 1.9375rem;
  height: 1.9375rem;
}
.coinBalance_coinsContainer_balance:first-of-type .coinBalance_coinsContainer_balance_amount {
  padding: 0 1rem 0 2rem;
}
.coinBalance_coinsContainer_balance:last-of-type {
  height: 1.75rem;
  margin-left: 0.75rem;
}
.coinBalance_coinsContainer_balance:last-of-type img {
  width: 2rem;
  height: 1.75rem;
}
.coinBalance_coinsContainer_balance:last-of-type .coinBalance_coinsContainer_balance_amount {
  padding: 0 1rem 0 2.2rem;
}
.coinBalance_logoutBtn {
  margin-right: 0.69rem;
  margin-left: 0.5rem;
  padding-top: 0.25em;
  width: 5.5rem;
  height: 1.875rem;
  border-radius: 1.6875rem;
  border: 2px solid #380993;
  background: #ff695f;
  box-shadow: 0px -4px 0px 0px #940f06 inset;
  color: #ffffff;
  font-family: var(--ff-montserrat);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.bottomNavbar {
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 29.0625rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 0.35rem;
  background: #395bd6;
  height: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  border-bottom: 0;
}
.bottomNavbar_navigationActions {
  width: 100%;
  padding: 0.2rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  background: #1e3482;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomNavbar_navigationActions li {
  width: 100%;
}
.bottomNavbar_navigationActions_actionBtns {
  min-width: 5rem;
  height: 2.375rem;
  border-radius: 1.6875rem;
  border: 2px solid #c0cdff;
  background: #3b65ff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bottomNavbar_navigationActions_actionBtns_icons {
  width: 1.25rem;
  height: 2.375rem;
  margin: 0 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.bottomNavbar_navigationActions_actionBtns_icons img {
  width: 1.25rem;
  height: 1.25rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.bottomNavbar_navigationActions_actionBtns_name {
  display: inline-flex;
  align-items: center;
  color: #c0cdff;
  font-family: var(--ff-russoOne);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}
.bottomNavbar_navigationActions_actionBtns.centerActionButton {
  margin: 0 0.25rem;
}
.bottomNavbar_navigationActions_actionBtns.active {
  border: 2px solid #452534;
  background: #ea6f6e;
}
.bottomNavbar_navigationActions_actionBtns.active .bottomNavbar_navigationActions_actionBtns_name {
  color: #ffffff;
}

.default {
  overflow-y: hidden;
}
.default_header {
  text-align: center;
  z-index: 1;
}
.default_header_h2 {
  color: #fbed63;
  text-shadow: 0px 1px 0px #6e0637;
  font-family: var(--ff-russoOne);
  font-size: 1.23138rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.default_header_h1 {
  color: #ffffff;
  text-shadow: 1px 3px 0px #6e0637;
  font-family: var(--ff-russoOne);
  font-size: 2.06888rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.default_actionButton {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5px;
}
.default_actionButton_register {
  height: 4.5rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.default_actionButton_register img {
  height: 4.5rem;
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.default_actionButton_login {
  color: #ffffff !important;
  font-family: var(--ff-montserrat);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.15rem;
  border-bottom: 1px solid #ffffff;
}

.earn {
  overflow-y: unset !important;
}

.earn_body .profile_body_prizeItems {
  height: 50%;
}
.earn_body .profile_body_prizeItems img {
  height: calc(100% - 2.75rem);
}
.earn_body .earnpage-marqueeContainer {
  border: 1px solid #000000;
  background: #ec6fdd;
  height: 2.75rem;
  font-size: 0.875rem;
  color: #fbeb5c;
  overflow-x: scroll;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.earn_body .earnpage-marqueeContainer::-webkit-scrollbar {
  display: none;
}
.earn_body .earnpage-marqueeContainer div {
  position: absolute;
  height: 2.75rem;
  top: 0;
  left: 0;
  transform: translate(-50%);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: marquee-animation 20s linear infinite alternate;
          animation: marquee-animation 20s linear infinite alternate;
}
.earn_body .earnpage-marqueeContainer div span {
  margin-left: 1.25rem;
  color: #fbeb5c;
  text-align: center;
  font-family: var(--ff-montserrat);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
}
.earn_body .earnpage-marqueeContainer div span:first-of-type {
  margin-left: 0;
}

.border-rect {
  margin: 0 auto;
  width: 100%;
  max-width: 29.0625rem;
  height: 0.5625rem;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  background: #3b65ff;
}
.border-rect.noTranslate {
  transform: translateY(-3.2rem);
}

.earnpage-rewardsList {
  padding: 1.3rem;
  border-bottom: 1px solid #000000;
  background: #395bd6;
  box-shadow: 0px 3px 0px 0px #3b65ff inset;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  display: flex;
  display: flex;
  margin: 0 auto !important;
  max-width: 29.0625rem;
  margin-bottom: 3.1rem !important;
}
.earnpage-rewardsList::-webkit-scrollbar {
  display: none;
}
.earnpage-rewardsList .earnpage-rewardsListItem {
  margin-left: 0.85rem;
  background: linear-gradient(180deg, #191945 0%, #2e2e8d 100%);
  border-radius: 0.5rem;
  border: 1px solid #000;
}
.earnpage-rewardsList .earnpage-rewardsListItem .rewardsListItem-rewardImage {
  width: 11.75rem;
  height: 14.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.earnpage-rewardsList .earnpage-rewardsListItem .rewardsListItem-rewardImage:last-of-type {
  background: linear-gradient(180deg, #ef7170 0%, #ea6f6e 100%);
  border-radius: 0.5rem;
  border: 1px solid #000;
}
.earnpage-rewardsList .earnpage-rewardsListItem .rewardsListItem-rewardImage img {
  width: 10.625rem;
  height: 10.875rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.earnpage-rewardsList .earnpage-rewardsListItem .rewardsListItem-rewardValue {
  height: 3.125rem;
  width: 100%;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffeb34;
  color: #000000;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  border-top: 1px solid #000;
  box-shadow: 0px 5px 0px 0px #fff59b inset, 0px -4px 0px 0px #b5a50f inset;
  font-family: var(--ff-montserrat);
  text-align: center;
}
.earnpage-rewardsList .earnpage-rewardsListItem .rewardsListItem-rewardValue .rewardValueTop {
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 600;
}
.earnpage-rewardsList .earnpage-rewardsListItem .rewardsListItem-rewardValue .rewardValueBottom {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
}
.earnpage-rewardsList .earnpage-rewardsListItem:first-of-type {
  margin-left: 0;
}
.earnpage-rewardsList .earnpage-rewardsListItem:last-of-type {
  background: linear-gradient(180deg, #ef7170 0%, #ea6f6e 100%);
}
.earnpage-rewardsList.defaultPage {
  padding: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
  justify-content: flex-start;
  align-self: center;
  transform: translateY(0);
  overflow-y: hidden;
  margin-bottom: 0 !important;
}
.earnpage-rewardsList.defaultPage .rewardsListItem-rewardImage {
  background: none !important;
}
.earnpage-rewardsList.defaultPage .rewardsListItem-rewardImage:first-of-type {
  background: none !important;
}
.earnpage-rewardsList.defaultPage .rewardsListItem-rewardImage:last-of-type {
  background: none !important;
}

.slotMachine-slotLine {
  width: 100%;
  height: 12.0625rem;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  margin-top: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: 0;
}
.slotMachine-slotLine::-webkit-scrollbar {
  display: none;
}
.slotMachine-slotLine .slotMachine-slotBg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slotMachine-slotLine .slotMachine-slotBg .slotMachine-luckySlotPointers {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slotMachine-slotLine .slotMachine-slotBg .slotMachine-luckySlotPointers:last-of-type {
  transform: rotateY(180deg);
}
.slotMachine-slotLine .slotMachine-slotBg .slotMachine-luckySlotPointers img {
  width: 1.5625rem;
  height: 1.1875rem;
}
.slotMachine-slotLine div:not([class]) {
  height: 6.9rem;
  width: calc(100% - 3rem);
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  margin-top: 1.6rem;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems:nth-of-type(1) {
  left: 0;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems:nth-of-type(2) {
  left: 50%;
  transform: translateX(-50%) translateY(50%);
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems:nth-of-type(3) {
  right: 0;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems.runningSlots.faster {
  -webkit-animation: slot-animation 250ms linear infinite alternate;
          animation: slot-animation 250ms linear infinite alternate;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems.runningSlots.faster:nth-of-type(2) {
  animation: slot-animation 250ms linear infinite alternate-reverse;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems.runningSlots.slower {
  -webkit-animation: slot-animation 400ms linear infinite alternate;
          animation: slot-animation 400ms linear infinite alternate;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems.runningSlots.slower:nth-of-type(2) {
  animation: slot-animation 400ms linear infinite alternate-reverse;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems .slotMachine-itemImages {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
}
.slotMachine-slotLine div:not([class]) .slotMachine-slotItems .slotMachine-itemImages img {
  width: 1.75rem;
  height: 1.75rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.earnpage-spinCount {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  color: #fff;
  text-align: center;
  font-family: var(--ff-montserrat);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

@-webkit-keyframes marquee-animation {
  from {
    left: -300%;
  }
  to {
    left: 0;
  }
}

@keyframes marquee-animation {
  from {
    left: -300%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes slot-animation {
  from {
    bottom: 150%;
  }
  to {
    bottom: -150%;
  }
}
@keyframes slot-animation {
  from {
    bottom: 150%;
  }
  to {
    bottom: -150%;
  }
}
.generatePass {
  overflow-y: hidden;
}
.generatePass_header {
  text-align: center;
  z-index: 1;
  color: #ffffff;
  text-shadow: 1px 3px 0px #6e0637;
  font-family: Russo One;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
}
.generatePass_header_stars {
  position: absolute;
  width: 1.6875rem;
  height: 2.125rem;
  z-index: 2;
  top: 0;
  right: 0;
  transform: translateX(1.135rem) translateY(-0.65rem);
}
.generatePass_passContainer {
  width: 19.5rem;
  z-index: 2;
  height: calc(100% - 0.7rem);
  position: relative;
  isolation: isolate;
}
.generatePass_passContainer_passBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
.generatePass_passContainer_passBg img {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}
.generatePass_passContainer_passTitle {
  height: 18.75%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 2.8571429253px 0px #6e0637;
  font-family: var(--ff-russoOne);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
}
.generatePass_passContainer_passBody {
  margin: 0 1.44rem 0 1.94rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--ff-montserrat);
}
.generatePass_passContainer_passBody.firstOfType {
  height: 15.25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.generatePass_passContainer_passBody.firstOfType div:last-of-type {
  min-width: 5rem;
}
.generatePass_passContainer_passBody.firstOfType div:first-of-type {
  max-width: 10rem;
}
.generatePass_passContainer_passBody.secondOfType {
  height: 13.8%;
}
.generatePass_passContainer_passBody.thirdOfType {
  height: 13.3%;
}
.generatePass_passContainer_passBody_label {
  color: #8b7f1b;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
}
.generatePass_passContainer_passBody_value {
  color: #000;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.generatePass_passContainer_passBody_sponsor {
  font-family: var(--ff-montserrat);
  margin: 0 1.44rem 0 1.94rem;
  margin-top: 0.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
}
.generatePass_passContainer_passBody_sponsor img {
  width: 5.49863rem;
  height: 0.91206rem;
  margin-left: 5px;
}
.generatePass_actionButton {
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.4rem;
  height: 5.375rem;
}
.generatePass_actionButton_registerButton {
  height: auto;
  width: 18.1875rem;
}
.generatePass_actionButton_registerButton img {
  height: auto;
  width: 18.1875rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.profile {
  overflow-y: hidden;
}
.profile_body {
  width: 19.5rem;
  height: 100%;
  z-index: 2;
  position: relative;
  isolation: isolate;
}
.profile_body_nftTicket {
  height: 10rem;
  font-family: var(--ff-montserrat);
}
.profile_body_nftTicket_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}
.profile_body_nftTicket_bg img {
  width: 100%;
  height: 10rem;
  -o-object-fit: fill;
     object-fit: fill;
}
.profile_body_nftTicket_ticketTitle {
  padding: 0 1.19rem 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 61%;
}
.profile_body_nftTicket_ticketTitle_title {
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 2px 0px #6e0637;
  font-family: var(--ff-russoOne);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: 1.4;
}
.profile_body_nftTicket_ticketTitle_img {
  width: 4rem;
  height: 4rem;
  margin-left: 1rem;
}
.profile_body_nftTicket_ticketBody {
  padding: 0 1.19rem 0 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 38%;
}
.profile_body_nftTicket_ticketBody div_label {
  color: #8b7f1b;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
}
.profile_body_nftTicket_ticketBody div_value {
  color: #000;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile_body_nftTicket_ticketBody div:last-of-type {
  min-width: 5rem;
}
.profile_body_nftTicket_ticketBody div:first-of-type {
  max-width: 10rem;
}
.profile_body_prizeItems {
  width: 100%;
  height: 280px;
  margin-top: 0.8rem;
}
.profile_body_prizeItems img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (max-height: 707px) {
  .profile_body {
    overflow-x: auto;
  }
}
.profile_actionButton {
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 0.4rem;
  height: 5.375rem;
}
.profile_actionButton_generateButton {
  height: auto;
  width: 18.1875rem;
}
.profile_actionButton_generateButton img {
  height: auto;
  width: 18.1875rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.register {
  overflow-y: hidden;
}
.register_header {
  text-align: center;
  z-index: 1;
  color: #ffffff;
  text-shadow: 1px 3px 0px #6e0637;
  font-family: var(--ff-russoOne);
  font-size: 2.06888rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  position: relative;
}
.register_header_stars {
  position: absolute;
  width: 1.6875rem;
  height: 2.125rem;
  z-index: 2;
  top: 0;
  right: 0;
  transform: translateX(1.135rem) translateY(-0.65rem);
}
.register_body {
  z-index: 2;
  width: 19.5rem;
  isolation: isolate;
  height: 100%;
}
.register_body_banner {
  position: relative;
  height: 12.1875rem;
}
.register_body_banner_bannerImg {
  position: absolute;
  z-index: -1;
}
.register_body_banner_bannerText {
  text-align: center;
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register_body_banner_bannerText_h2 {
  width: 16.3125rem;
  color: #fff;
  text-align: center;
  text-shadow: 0px 1px 0px #6e0637;
  font-family: var(--ff-russoOne);
  font-size: 1rem;
  font-weight: 400;
  position: relative;
}
.register_body_banner_bannerText_h2_stars {
  position: absolute;
  right: 0.25rem;
  top: 0;
  transform: translateY(-50%);
}
.register_body_form {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.register_body_form_controlGroup {
  display: flex;
  align-items: center;
  height: 3.125rem;
  border-radius: 0.25rem;
  border: 1px solid #000000;
  background: #ffffff;
  margin-top: 0.35rem;
}
.register_body_form_controlGroup_icon {
  width: 2.18256rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register_body_form_controlGroup_control {
  width: 100%;
  height: 100%;
  color: #000000;
  font-family: var(--ff-montserrat);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
}
.register_body_form_controlGroup_control::-moz-placeholder {
  color: #c5c5c5;
}
.register_body_form_controlGroup_control:-ms-input-placeholder {
  color: #c5c5c5;
}
.register_body_form_controlGroup_control::placeholder {
  color: #c5c5c5;
}
@media screen and (max-height: 660px) {
  .register_body {
    overflow-x: auto;
  }
}
.register_actionButton {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 4px;
  height: 5.6875rem;
}
.register_actionButton_registerButton {
  height: auto;
  width: 15.25rem;
}
.register_actionButton_registerButton img {
  height: auto;
  width: 15.25rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.register_actionButton_loginButton {
  color: #ffffff !important;
  font-family: var(--ff-montserrat);
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.15rem;
  border-bottom: 1px solid #ffffff;
}

.rewardspage-rewardsList .rewardspage-rewardsListItem {
  width: 100%;
  position: relative;
  margin-bottom: 0.9rem;
  border-radius: 0.5rem;
  overflow: hidden;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem:last-of-type {
  margin-bottom: 0;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem .rewardsListItem-rewardImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem .rewardsListItem-rewardImage img {
  width: 100%;
  height: 21.625rem;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem div .rewardsListItem-rewardValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 2.5625rem;
  border-radius: 1.25rem;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 0px -4px 0px 0px #bfccff inset;
  font-size: 1.25225rem;
  font-style: normal;
  font-weight: 400;
  font-family: var(--ff-russoOne);
  color: #000000;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem div .rewardsListItem-rewardValue.disabled {
  background: #c3c3c3;
  box-shadow: 0px -4px 0px 0px #a2a3a5 inset;
  color: #7c7c7c;
}
.rewardspage-rewardsList .rewardspage-rewardsListItem div .rewardsListItem-rewardValue img {
  width: 1.73513rem;
  height: 1.47225rem;
  margin: 0 0.2rem;
}
.rewardspage-rewardsList .rewardspage-disabledRewards {
  position: relative;
  padding-bottom: 2rem;
}
.rewardspage-rewardsList .rewardspage-disabledRewards::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.78);
}
.rewardspage-rewardsList .rewardspage-disabledRewards .rewardsListItem-rewardValue img {
  margin-right: 0.2rem;
}

/* --- Resets --- */
/* --- Components --- */
/* --- Pages --- */
.app {
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;
  background: transparent;
}/*# sourceMappingURL=main.css.map */