/* --- Resets --- */
@forward "./utils/resets";

/* --- Components --- */
@forward "./components/common";
@forward "./components/shared";

/* --- Pages --- */
@forward "./pages/default";
@forward "./pages/earn";
@forward "./pages/generatepass";
@forward "./pages/home";
@forward "./pages/register";
@forward "./pages/rewards";

.app {
  // width: 28rem;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;
  background: transparent;
}
