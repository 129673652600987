@use "../utils/functions" as *;

.profile {
  overflow-y: hidden;

  &_body {
    width: 19.5rem;
    height: 100%;
    z-index: 2;
    position: relative;
    isolation: isolate;

    &_nftTicket {
      height: 10rem;
      font-family: var(--ff-montserrat);

      &_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10rem;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        justify-content: center;
        z-index: -1;

        img {
          width: 100%;
          height: 10rem;
          object-fit: fill;
        }
      }

      &_ticketTitle {
        padding: 0 1.19rem 0 1.25rem;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        justify-content: space-between;
        height: 61%;

        &_title {
          color: #ffffff;
          text-align: center;
          text-shadow: 0px 2px 0px #6e0637;
          font-family: var(--ff-russoOne);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          width: 100%;
          line-height: 1.4;
        }

        &_img {
          width: 4rem;
          height: 4rem;
          margin-left: 1rem;
        }
      }

      &_ticketBody {
        padding: 0 1.19rem 0 1.25rem;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 38%;

        div {
          &_label {
            color: #8b7f1b;
            font-size: 0.8rem;
            font-style: normal;
            font-weight: 500;
          }

          &_value {
            color: #000;
            font-size: 0.95rem;
            font-style: normal;
            font-weight: 700;
            margin-top: 2px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        div:last-of-type {
          min-width: 5rem;
        }

        div:first-of-type {
          max-width: 10rem;
        }
      }
    }

    &_prizeItems {
      width: 100%;
      height: 280px;
      margin-top: 0.8rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    @media screen and (max-height: 707px){
      overflow-x: auto;
    }
  }

  &_actionButton {
    z-index: 1;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: flex-end;
    justify-content: center;
    margin-top: 0.4rem;
    height: 5.375rem;

    &_generateButton {
      height: auto;
      width: toRem(291);

      img {
        height: auto;
        width: toRem(291);
        object-fit: cover;
      }
    }
  }
}
