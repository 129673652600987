@use "../utils/functions" as *;

/* --- Header Bar Long --- */
.headerBar {
  height: 6.8125rem;
  margin-top: 0.75rem;
  font-family: var(--ff-russoOne);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: center;
  justify-content: center;
  position: relative;

  &_bg {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.8125rem;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}

/* --- Header Bar Short --- */
.headerShort {
  height: 3.75rem;
  margin-top: 0.75rem;
  font-family: var(--ff-russoOne);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: flex-start;
  justify-content: center;
  position: relative;

  &_bg {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.75rem;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}

/* --- Machine Body --- */
.machineBody {
  padding: 1.7rem 0;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: flex-start;
  justify-content: center;

  &_bgImg {
    position: absolute;
    top: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: center;

    img {
      width: 23rem;
      object-fit: fill;
    }
  }
}

/* --- Machine Button --- */
.machineButton {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: flex-start;
  justify-content: center;
  height: 9.125rem;
  overflow: hidden;

  &_bgImg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    align-items: center;
    justify-content: center;
    z-index: 0;

    img {
      width: toRem(465);
      height: 100%;
      max-width: toRem(465);
      max-height: toRem(188);
    }
  }
}

/* --- App Spinner --- */

.appSpinner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  align-items: center;
  justify-content: center;
  height: 100vh;
  height: 100dvh;

  .appSpinner-spinner {
    width: 4rem;
    height: 4rem;
    border: 5px dotted #000000;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 2s linear infinite;
  }
}

/* --- Modal --- */
.modalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: rgba(3, 3, 83, 0.8);
  backdrop-filter: blur(12.5px);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .modalBody {
    width: 20rem;
    height: 20rem;
    border: 1px solid #000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #5a7aef;
    box-shadow: 0px 3px 0px 0px #3b65ff inset;

    &.unluckyBody {
      background: #ee7170;
    }

    .modalHeader {
      height: 3.625rem;
      width: 20rem;
      margin-top: 2px;
      color: #ffffff;
      text-align: center;
      font-family: var(--ff-russoOne);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.03em;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    .modalContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(20rem - 3.625rem - 5px);
      padding: 1.12rem 1.2rem 1.12rem 1.2rem;
      border-top: 1px solid #000000;

      .modalTitle {
        color: #ffeb34;
        text-align: center;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
        font-family: var(--ff-russoOne);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
      }

      .modalIllustration {
        width: 8rem;
        height: 8rem;
        margin: 0.62rem 0;

        &.unlockMIllustration {
          width: 17.6875rem;
          height: 13.125rem;
          margin-top: -2.5rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .modalActionButton {
        width: 14rem;
        height: 2rem;
        border-radius: 1.6875rem;
        border: 2px solid #452534;
        background: #ea6f6e;
        color: #fff;
        text-align: center;
        font-family: var(--ff-russoOne);
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.04em;

        &.unluckyAction {
          background: #5a7aef;
        }
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
