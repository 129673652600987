@use "../utils/functions" as *;

.rewardspage-rewardsList {
  .rewardspage-rewardsListItem {
    width: 100%;
    position: relative;
    margin-bottom: 0.9rem;
    border-radius: 0.5rem;
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 0;
    }

    .rewardsListItem-rewardImage {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 21.625rem;
      }
    }

    div {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4.5rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      align-items: center;
      justify-content: center;

      .rewardsListItem-rewardValue {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        align-items: center;
        justify-content: center;
        width: 14rem;
        height: 2.5625rem;
        border-radius: 1.25rem;
        border: 1px solid #000;
        background: #fff;
        box-shadow: 0px -4px 0px 0px #bfccff inset;
        font-size: 1.25225rem;
        font-style: normal;
        font-weight: 400;
        font-family: var(--ff-russoOne);
        color: #000000;

        &.disabled {
          background: #c3c3c3;
          box-shadow: 0px -4px 0px 0px #a2a3a5 inset;
          color: #7c7c7c;
        }

        img {
          width: 1.73513rem;
          height: 1.47225rem;
          margin: 0 0.2rem;
        }
      }
    }
  }

  .rewardspage-disabledRewards {
    position: relative;
    padding-bottom: 2rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.78);
    }

    .rewardsListItem-rewardValue {
      img {
        margin-right: 0.2rem;
      }
    }
  }
}
